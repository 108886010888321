.App {
  text-align: center;
}


#parent-div {
  margin-top: 8vh;
  display: flex;
  align-items: center;
}

#home {
  margin-left: 10px;
}